<template>
  <div>
    <div class="meeting_warp" :style="{ '--themecolor': themecolors }">
      <div class="meeting_kv" v-if="kvShow && loading">
        <img :src="kv_url" alt="">
      </div>
      <div class="meeting_content" :class="{ 'flex': tabList.length == 0 }"
        :style="{ 'minHeight': kvShow ? 'calc(100% - 2.47rem)' : 'calc(100% - .36rem)' }" v-if="loading">
        <div class="meeting_tab">
          <div class="tab_tiem" :class="{ 'active_tiem': item.id == acriveData.id }" v-for="(item, index) in showList"
            :key="index" @click="Change_active(item, index)">
            <p>{{ item.title }}</p>
          </div>
          <div class="tab_tiem icon" @click="showPopup" v-if="iconShow"><van-icon name="ellipsis" /></div>
        </div>
        <div class="meeting_list" v-if="MeetingList.length != 0">
          <template v-if="loading_list">
            <div
              :class="{ 'meeting_list_brief': acriveData.left_type == 2, 'meeting_list_warp': acriveData.left_type == 1 }"
              @click="go_particulars(item.id)" v-for="(item, index) in MeetingList" :key="index">
              <div class="meeting_img" v-if="acriveData.left_type == 1">
                <img :src="item.cover_url" alt="">
              </div>
              <div class="meeting_info">
                <div class="m_infn_text"><span class="m_info_name" v-html="item.conference_name"></span> 
                  <van-icon name="arrow" style="font-weight: bold;" v-if="acriveData.left_type == 2" />
                </div>
              </div>
            </div>
          </template>

          <van-loading :color="themecolors" :text-color="themecolors" class="loading_list" v-if="!loading_list" />
        </div>
        <van-empty class="list_null" :image="require('@/assets/null.svg')" image-size="3rem"
          v-if="MeetingList.length == 0" />
      </div>
      <van-loading :color="themecolors" :text-color="themecolors" class="loading_s" v-if="!loading"
        :style="{ 'minHeight': 'calc(100% - .36rem)' }" />
      <sideBar v-if="loading"></sideBar>
      <Support />
      <van-popup v-model="show" position="top">
        <div class="popup_warp">
          <div class="popup_nav">
            导航
          </div>
          <div class="meeting_tab popup_content">
            <div class="tab_tiem popup_tiem" :class="{ 'active_tiem': item.id == openData.id }"
              v-for="(item, index) in tabList" :key="index" @click="poen_Change(item, index)">
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div class="popup_btn">
            <div class="btn_common" @click="Changereset()">重置</div>
            <div class="btn_common accomplish" @click="open_cange()">完成</div>
          </div>
        </div>
      </van-popup>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex'
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
export default {
  components: {
    sideBar,
    Support
  },
  computed: {
    ...mapState(['themecolors']),
  },
  data() {
    return {
      show: false,//是否显示弹窗
      iconShow: false,//更多按钮显示
      loading_list: true,
      acriveData: {},//默认选中
      activeIndex: 0,
      openData: {},//预选中数据
      openIndex: 0,//预选中数据
      loading: false,
      kvShow: false,//是否显示kv
      kv_url: '',
      tabList: [],
      showList: [],//显示的日历列表
      MeetingList: [],
    }
  },
  mounted() {
    this.get_front_calendar_kv()
  },
  created(){
    if(sessionStorage.getItem('index')){
      this.activeIndex=sessionStorage.getItem('index')
    }
  },
  methods: {

    showPopup() {
      this.show = true
    },
    // 预选操作
    poen_Change(item, index) {
      this.openData = item
      this.openIndex = index
    },
    // 完成
    open_cange() {
      this.show = false
      this.loading_list = false
      this.acriveData = this.openData
      this.activeIndex = this.openIndex
      this.get_front_calendar_conference_list()
    },
    Change_active(item, index) {
      if (item.id == this.acriveData.id) {
        return
      }
      this.activeIndex = index
      this.loading_list = false
      this.acriveData = item
      this.openData = item
      this.get_front_calendar_conference_list()
    },
    get_front_calendar_kv() {
      this.$store.dispatch('get_front_calendar_kv', {
        site_id: this.$route.query.page_id
      }).then(res => {
        if (res.data.code == 200) {
          this.get_front_calendar_list()
          if (res.data.data.kv_status == 1) {
            this.kv_url = res.data.data.kv_url
            this.kvShow = true
            return
          }
          this.kvShow = false
        }
      })
    },
    get_front_calendar_list() {
      this.$store.dispatch('get_front_calendar_list', {
        site_id: this.$route.query.page_id
      }).then(res => {
        if (res.data.code == 200) {
          this.tabList = res.data.data
          if (res.data.data.length != 0) {
            this.acriveData = res.data.data[this.activeIndex]
            this.openData = this.acriveData
            if (this.tabList.length > 3) {
              this.iconShow = true
              this.showList = this.tabList.slice(0, 3)
            } else {
              this.iconShow = false
              this.showList = this.tabList
            }
            this.get_front_calendar_conference_list()
          } else {
            this.loading = true
          }

        }
      })
    },

    // 日历列表接口
    get_front_calendar_conference_list() {
      this.$store.dispatch('get_front_calendar_conference_list', {
        calendar_id: this.acriveData.id
      }).then(res => {
        if (res.data.code == 200) {
          this.MeetingList = res.data.data
          if(this.acriveData.left_type == 2){
            let reg=new RegExp('<br>|<br/>','g')
            this.MeetingList.forEach(item=>{
              item.conference_name =  item.conference_name.replace(reg,'')
            })
          }
        }
      }).finally(() => {
        this.loading = true
        this.loading_list = true
      })
    },

    // 重置
    Changereset() {
      if (this.tabList.length == 0) {
        return
      }
      this.acriveData = this.tabList[0]
      this.openData = this.acriveData
      this.show = false
      this.get_front_calendar_conference_list()
    },
    go_particulars(id) {
      sessionStorage.setItem('index', this.activeIndex)
      this.$router.push({ path: '/meeting_info', query: { id: id} });
    }
  },
}
</script>

<style lang="scss" scoped>
.meeting_warp {
  height: 100%;
  overflow-y: auto;

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .meeting_kv {
    width: 100%;
    height: 2.11rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .meeting_content {
    padding: 0.16rem 0.16rem 0;
    position: relative;

    .meeting_list {
      margin-top: .16rem;


      .meeting_list_warp {
        padding: 0 0.05rem;
        margin-bottom: .1rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        height: 0.7rem;

        .meeting_img {
          display: flex;
          width: 1.24rem;
          height: 100%;
          border-radius: 0.1rem;
          overflow: hidden;
          flex-shrink: 0;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .meeting_info {
          flex: 1;
          font-size: .12rem;
          margin: 0 0.1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;

          .m_infn_text {
            width: 100%;
            font-weight: 700;
            font-size: .15rem;
            margin-top: 0.05rem;
            color: #000;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .m_info_name {
            word-break: break-all;
          }
        }
      }

      // 简介模式
      .meeting_list_brief {
        height: .44rem;
        line-height: .44rem;
        background: #F2F6F8;
        border-radius: 0.08rem;
        margin-bottom: .1rem;

        .meeting_info {
          flex: 1;
          font-size: .12rem;
          margin: 0 0.1rem;

          .m_infn_text {
            width: 100%;
            font-weight: 700;
            font-size: .15rem;
            margin-top: 0.05rem;
            color: #000;
            display: flex;
            align-items: center;

            .m_info_name {
              flex: 1;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
              white-space: nowrap;
              margin-right: .1rem;
            }
          }

          .m_infn_time {
            color: #999;
          }
        }
      }
    }
  }

  // 公用样式
  .meeting_tab {
    font-size: .14rem;
    display: flex;

    .tab_tiem {
      padding: 0 .12rem;
      color: #86909C;
      width: .9rem;
      margin-left: .12rem;
      background: #F2F6F8;
      border-radius: .08rem;
      height: .32rem;
      line-height: .32rem;
      overflow: hidden;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      p {
        white-space: nowrap;
        overflow-x: auto;
      }
    }

    .icon {
      width: .34rem;

      .van-icon {
        font-weight: bold;
        color: #2c2c2c;
      }
    }

    .active_tiem {
      color: #fff;
      background: var(--themecolor);
    }
  }

  .popup_warp {
    padding: 0 0.16rem 0.2rem;

    .popup_nav {
      color: #1D2129;
      font-size: .16rem;
      padding: .1rem;
      border-bottom: .01rem solid #F2F6F8;
      margin-bottom: .16rem;
      font-weight: bold;
    }

    .popup_content {
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: .01rem solid #F2F6F8;
      height: 1.45rem;
      overflow-y: auto;

      .popup_tiem {
        width: 1.04rem;
        margin-bottom: 0.16rem;

        &:nth-child(3n-2) {
          margin-left: 0;
        }
      }
    }

    .popup_btn {
      display: flex;
      justify-content: space-between;
      margin-top: .2rem;

      .btn_common {
        width: 1.62rem;
        height: .44rem;
        line-height: .44rem;
        background: #F2F6F8;
        border-radius: .08rem;
        color: #86909C;
        font-size: .14rem;
        text-align: center;
        cursor: pointer;
      }

      .accomplish {
        color: #fff;
        background: var(--themecolor);
      }
    }
  }

  .van-popup {
    border-radius: 0 0 .1rem .1rem;
  }

  .loading_s {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list_null {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading_list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>